
.video_small_container
{
    margin: 10px;
    width: 90%;
    overflow: auto;
    overflow-y: hidden;
    margin: 0 auto;
    white-space: nowrap;
    scrollbar-color: $primary $secondary;
    scrollbar-width: thin;
}
.video_small_line
{
      height: 160px;
  width: 40%;
}

.video_small_line img
{
    display: inline;
    height: 140px;
    width: auto;
}

.video-title
{
    margin-top: 4px;
    
    .artist
    {
        color:darken($primary, 10%);
        font-size: 1.15em;
        font-weight: bold;
        margin-bottom: 0px;
    }
    
    .subtitle
    {
        margin-top: 0px;
        font-size: 0.9em;
    }
    
    .maintitle
    {
        color:darken($primary, 15%);
        margin-top: 0px;
        font-size: 1.2em;
    }
    
    
    
}





.video-thumbnail 
{
    position: relative;
    text-align: center;
    color: white;
  
    .time-info
    {
      position: absolute;
      top: 8px;
      left: 16px;
      background-color: rgba(0,0,0,0.8);
      padding-left: 6px;
      padding-right: 6px;
    }

   
    .video-icons {
      position: absolute;
      top: 8px;
      right: 16px;
      background-color: rgba(0,0,0,0.8);
      padding-left: 6px;
      padding-right: 6px;
    }

    .duration {
      position: absolute;
      bottom: 8px;
      right: 16px;
    } 
    
    .youtube-user
    {
      position: absolute;
      bottom: 8px;
      left: 8px;
        
    }

}
 
