
$primary: #065353;
$main-background-color: rgb(39, 43, 48);

 

.sub-menu
{
    //btn-group btn-group-justified
    @extend .btn-group !optional;
    @extend .btn-group-justified !optional;
}

.sub-menu a
{
    @extend .btn !optional;
    @extend .btn-block !optional;
}


.btn-research
{
    @extend .btn !optional;
    @extend .btn-block !optional;
    @extend .btn-primary !optional;
    @extend .btn-lg !optional;
    width: 100%;
}


.sub-menu a.menu-link-active
{
    @extend .btn-warning !optional;
}

.sub-menu a.menu-link
{
    @extend .btn-primary !optional;
}

