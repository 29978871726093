#toolbar-player 
{
    margin:0px;
}

#toolbar-player .btn 
{
    
  background-image: linear-gradient($primary, darken($primary,5%) 60%, darken($primary,10%));  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 1.8em;
  border: 1px solid darken($primary,2%);
}

#toolbar-player .btn:hover
{
//  background-image: linear-gradient(#066060, #044343 60%, #002020);
  background-image: linear-gradient($primary, darken($primary,10%) 60%, darken($primary,30%));
  border: 1px solid ligthen($primary,12%);
}


#toolbar-player .btn.history-disabled
{
  @extend .btn-danger;
 
}

#toolbar-player .btn.history-enabled, #toolbar-player .btn.btn-warning
{
  background-image: linear-gradient($warning, darken($warning,10%) 60%, darken($warning,30%));
}

#toolbar-player .btn.btn-danger
{
  background-image: linear-gradient($danger, darken($danger,10%) 60%, darken($danger,30%));
}

.btn-group-steam .btn-steam
{
      color:white;
      line-height: 64px;
      font-size: 3em;
      margin: 2px;
      padding: 0px;
      text-align: center;
      width: 64px;
      height: 64px;
      display: inline-block;
      border-radius: 0px;
}

.btn-steam-danger
{
      background-image: linear-gradient($danger, darken($danger,10%) 60%, darken($danger,30%));
    
}

.btn-steam-warning
{
  background-image: linear-gradient($warning, darken($warning,10%) 60%, darken($warning,30%));
}    


.btn-steam-primary
{
  background-image: linear-gradient($primary, darken($primary,5%) 60%, darken($primary,10%));  background-repeat: no-repeat;
}    


