


.artist-list
{
    text-align: center;
}

.artist-list li
{
    display: inline-block;
    background-image: linear-gradient($primary, darken($primary,5%) 60%, darken($primary,10%));  background-repeat: no-repeat;
    color: white;
    border-radius: 6px;
    padding: 0.2em  1em 0.2em  1em;
    margin: 0.3em;
}
.artist-list li a
{
    color: white;
    text-decoration: none;
}


.albums
{
    text-align: center;
    color:white;
}

.albums img.cover
{
    height: 160px;
    transition-duration: 1s;
    transition-delay: 0.1s;
    
}
.albums img.cover:hover
{
    height: 175px;
}

.albums .cover
{
    filter: saturate(60%);
    border: 2px solid $primary;
}


.albums .cover:hover
{
    filter: saturate(100%);
}

.albums .cover.noVideo
{
    border: 2px solid $danger;
    filter: saturate(10%);
}
.albums .cover.noVideo:hover
{
    filter: saturate(25%);
}



.playlist-card
{
    background-color: white;
    img
    {
        width:100%;
    }
}