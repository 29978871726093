

#radio
{
    background-color: $primary;
    font-size: 1.2em;
    width: 100%;
    color:white;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    margin:0px;
}



/*
    margin: 10px;
    width: 90%;
    overflow: auto;
    overflow-y: hidden;
    margin: 0 auto;
    white-space: nowrap;
    scrollbar-color: $primary $secondary;
    scrollbar-width: thin;
*/

/* ------------------------------------ PLAYLISTE */ 
/*
#currentplaylist
{
 overflow-y:auto; 
 height:500px;
 background-color:#000000;
 padding:0;
 box-sizing: border-box;
}
*/
#playlist, #container-video-playlist, body
{
    //height: 100%;
}
 

#container-video-playlist
{
    // marche pas
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) ;
    height: 100%;
}


#currentplaylist_liste.hide
{
  	display:none;
}
 
 
#currentplaylist_liste li.nowplaying
{
	background-color: #065353;
	color:#ffffff;
        transition-duration: 2s;
}

/*
li.inqueuetoplay
{
	background-color:#ffffff;
	color:#297c7c;
	border:solid #297c7c 1px; 
	box-sizing: border-box;
        border-collapse: collapse;
}

*/




#currentplaylist_liste 
{

    top:0px;
    display:block;

    width:100%;
    padding:0;
    margin:0;	
    border-bottom:solid #297c7c 2px;
    border:solid red 2px;

    background-color: black;
    box-sizing: border-box;
    scrollbar-color: $primary black;
    scrollbar-width: thin;
    overflow-x: hidden;
    overflow-y: auto;    
    
    li
    {
        height: 70px;
        box-sizing: border-box;

        display:block;

	border-top:solid $primary 1px;
	border-bottom:solid $primary 1px;
//        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color:white;
        position: relative;
         
        .thumbnail
        {
            box-sizing: border-box;
            display:inline-block;
            height: 80px;
            margin:0px;
            padding:0px;
        }
        
        .video_title
        {
            box-sizing: border-box;
            display:inline-block;
            width:80%;
        }
        
        .playlist_icons
        {
        
            box-sizing: border-box;
            display:inline-block;

            width: 5%;
            right: 0px;
            height: 100%;
            background-color: rgba(0,0,0,0.8);
            padding:5px;
            position: absolute; 
        }
    }
    li.played
    {
        color:#777777 !important;
        height: 25px;
        img
        {
            height:25px;
                opacity: 0.4;
                filter: alpha(opacity=40); /* For IE8 and earlier */
        }
            
    }
        
}

#currentplaylist_liste li .playlist_infos
{
	font-size: 0.6em;
	width:86%;
	height:100%;
}

/*
#currentplaylist_liste li .icone_beta
{
    display:table-cell;
    height:100%;
    padding:5px;
    box-sizing: border-box;
    vertical-align:middle;
    float:right;
}

#currentplaylist_liste li .icone_beta i:hover 
{
    cursor: pointer;
    color:#297c7c;
}

#currentplaylist_liste li .icone_beta i.enleve_playlist:hover 
{
    color:#dd0000;
}
*/


#currentplaylist_liste .main_video_info li
{
	border-bottom:solid 1px #297c7c;
	border-top:solid 1px #297c7c;
	box-sizing: border-box;
}

