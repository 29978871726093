
$primary: #065353;
$main-background-color: rgb(39, 43, 48);

 

@import "~bootstrap/scss/bootstrap"; 

//@import "~font-awesome/scss/font-awesome.scss";
@import '~@fortawesome/fontawesome-free/css/all.css';


@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import "./playlist.scss";
@import "./toolbar_player.scss";
@import "./music.scss";
@import "./menu.scss";
@import "./radio.scss";
@import "./video.scss";

@import "./metallum.scss";


@import "./admin.scss";


.btn-admin
{
    @extend .btn !optional;
    @extend .btn-block !optional;
    @extend .btn-danger !optional;
}

td.longtext_hide {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
}



body {
    background-color: $main-background-color;
    overscroll-behavior-y: contain;
}

a
{
    color: white;
}

h1
{
//    background-color: darken($primary,2%);
//    background: radial-gradient(ellipse at center, darken($primary,15%),ligthen($primary,15%));
    background-image:linear-gradient(to left bottom,#A0342F,$primary);
    color: white;
    text-align: center;
    padding: 0.5em;
}


// .banner-youtube h1
#header h1
{
    background-image:none;
    background-color: rgba(10,10,10,0.4);
}



h2
{
    background-color: $primary;
    color: white;
    text-align: center;
    padding: 0.3em;
}


input.search
{
    width: 90%;
    margin:5%;
    font-size: 3em;
}



.separateur
{
    clear:both;
    border: 0;
}

.card-header a
{
    color: $primary;
}

.card.youtube-user h5 img, img.imgintitle
{
    height:30px;
    padding-right: 8px;
}

.card.youtube-user .banner, .card .thumbnail, 
{
    width:100%;
    border-radius: 6px;
    box-shadow: 10px 5px 5px $primary;
}

.thumbnail-short-contaner
{
    width: 100%;
    overflow: hidden;
}

.card .thumbnail.thumbnail-short
 {
     position: relative;
     left: -50%;
     width: 180%;
     object-fit: cover;
     border-color: 2px red solid;
     
 }




.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}
.btn-group-justified .btn,
.btn-group-justified .btn-group {
    float: none;
    display: table-cell;
    width: 1%;
}
.btn-group-justified .btn .btn,
.btn-group-justified .btn-group .btn {
    width: 100%;
}
.btn-group-justified .btn .dropdown-menu,
.btn-group-justified .btn-group .dropdown-menu {
    left: auto;
}



.btn-group-steam
{
    background-color: black;
}


.btn-group-steam .btn
{
    margin: 1em;
}




#container-main
{
    width: 70%;    
    float: left;
}



#container-video-playlist
{
    width: 30%;    
    float: right;
    right: 0px;
    top:0px;
    position: fixed;
}

@media (max-width: 765px) 
{
    #container-main
    {
        width: 100%;    
    }
    #video-player
    {
        z-index: 100;
    }
    
    #container-video-playlist
    {
        width: 100%;    
    }
}


#video-player, #video
{
    
    .shrink
    {
    height:100px;
    }
}

#phone-body
{
    
    #video-player, #video
    {
        width: 100%;
        height:200px;
        transition: all 0.4s ease-in-out;
    }
    
    .scroll-down
    {
        #video-player, #video
        {
            width: 100%;
            height:100px;
            transition: all 0.4s ease-in-out;
        }
    }
    /*
    .horizontal-view
    {
        #video-player, #video
        {
            width: 100%;
            height:100%;
            transition: all 0.4s ease-in-out;
        }
    }
    */
}





#phone-header
{
    background-color: #cccccc;
    z-index: 500;
    position: sticky; 
    top: 0;
}


/*
#main-content h1
{
    position: sticky; 
    top: 0;
    background-color: red;
    
}
*/




.description
{
    background-color: rgba(255,255,255,0.75);
    border-radius: 12px;
    border: 1px solid $primary;
    padding: 1em;
}






#info-detail-title img 
{
    height: 45px;
    border-radius:  5px;
    margin-right: 12px;
}

#info-detail-content img
{
    height: 200px;
    border-radius:  15px;
    margin-right: 12px;
    margin-bottom: 12px;
    float: left;
}


.swipe
{
    background-color: red;
}


#info-video-detail a
{
    color: $primary;
}


.launch-radio
{
    font-size: 4em;
    background-color: $primary;
    color: white;
    border-radius:9px; 
   cursor : pointer;
 
}
    
.biblio-icon
{
//    font-size: 3em;
    padding:1em;
    border-radius:7px; 
    padding: 0em 0.2em 0em 0.2em;
    cursor : pointer;
    transition-duration: 1s;
}

.biblio-icon.suscribed
{
    background-color: $primary;
    border:1px solid darken($primary,10%);
}

.biblio-icon.notsuscribed
{
    background-color: $warning;
    border:1px solid darken($warning,20%);
}


.artist.suscribed
{
    background-color: $primary;
    border:1px solid darken($primary,10%);
}

.artist.notsuscribed
{
    background-color: $warning;
    border:1px solid darken($warning,20%);
}

.tab-pane
{
    color:white;
    a
    {
        color:$primary;
    }
}
