


.table-admin
{
    background-color: #cccccc;
}

.table-admin td.danger
{
    background-color: #660000;
    color: #ffffff;
}

.table-admin 
{
    .admin-ago
    {
        &.year
        {
            background-color: #660000;
            color: #ffffff;
        }
        &.month
        {
            background-color: #FFA500;
            color: #ffffff;
        }
        &.day
        {
            background-color: #005500;
            color: #ffffff;
        }
        &.hour
        {
            background-color: #00aa00;
            color: #ffffff;
        }
        &.minute
        {
            background-color: #00ff00;
            color: #000000;
        }
        &.unknow
        {
            background-color: #0000ff;
            color: #000000;
        }
        
        
    }
    
    
}

.table-admin img
{
    max-height: 55px;
    max-width: 55px;
}