

.metal_archive 
{
    
    .float_left
    {
        width: 50%;
        float: left;
        margin: 0;
        padding: 0;
        
        dt
        {
            width: 105px;
            float: left;
        }
        dd
        {
            margin-left: 105px;
        }
    }
    
    .float_right
    {
        width: 50%;
        float: right;
        margin: 0;
        padding: 0;
    }
    
    
    
}